import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { ContactFeedback, Page } from "@/types";

type FeedbackState = {
  feedbackPage: Page<ContactFeedback>;
};

const feedback: Module<FeedbackState, unknown> = {
  namespaced: true,
  state: () => ({
    feedbackPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getFeedback: (state) => (feedbackId: string) =>
      state.feedbackPage.docs.find((c) => c._id === feedbackId),
    feedbackPage: (state) => state.feedbackPage,
  },
  mutations: {
    ADD_FEEDBACK: (state, _feedback) => {
      let idx = -1;
      state.feedbackPage.docs.map((r, i) => {
        if (r._id === _feedback._id) idx = i;
      });
      if (idx === -1) state.feedbackPage.docs.push(_feedback);
      else Vue.set(state.feedbackPage.docs, idx, _feedback);
    },
    SET_FEEDBACK_PAGE: (state, list) => {
      state.feedbackPage = list;
    },
    REMOVE_FEEDBACK(state, feedback) {
      let idx = -1;
      state.feedbackPage.docs.map((r, i) => {
        if (r._id === feedback._id) idx = i;
      });
      if (idx > -1) state.feedbackPage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchFeedbackList(context, params = "") {
      api
        .get(`/v1/contact-feedback${params}`)
        .then((response) => {
          context.commit(
            "SET_FEEDBACK_PAGE",
            response.data.contactFeedbackPage
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    updateFeedback(context, data: { id: string; feedback: ContactFeedback }) {
      api
        .put(`/v1/contact-feedback/${data.id}`, data.feedback)
        .then((response) => {
          context.commit("ADD_FEEDBACK", response.data.feedback);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "ContactFeedback updated",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default feedback;
