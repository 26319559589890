








import ContactFeedbackList from "@/components/ContactFeedbackList.vue";
import Vue from "vue";

export default Vue.extend({
  name: "Feedback",
  components: { ContactFeedbackList },
});
