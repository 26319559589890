
















import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import feedbackStoreModule from "@/store/modules/feedback";
import { Business, Role } from "@/types";

const { mapActions: actions, mapGetters: getters } =
  createNamespacedHelpers("BILLING_");

export default Vue.extend<any, any, any, any>({
  name: "ContactFeedbackList",
  data: () => ({
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Email", value: "email" },
      // { text: "Shop", value: "shop.name" },
      { text: "message", value: "message" },
      { text: "Added", value: "createdAt" },
    ],
    options: {} as { page: number },
  }),
  watch: {
    role() {
      if (this.role) {
        this.fetchData();
      }
    },
    options: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
  },
  computed: {
    ...getters(["feedbackPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...actions(["fetchFeedbackList"]),
    fetchData() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        const params = `?businessId=${bid}&page=${this.options.page || 1}`;
        this.fetchFeedbackList(params);
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("BILLING_")) {
      this.$store.registerModule("BILLING_", feedbackStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("BILLING_");
  },
});
